<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h3><b>Ve a quién le agradas</b></h3>

          <h3>Da y recibe cumplidos de tus amigos</h3>
          <div class="">
            <a href="https://apps.apple.com/app/glix/id6476934520" target="_new" class="btn-get-started scrollto">Descargar App</a>
          </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img">
          <img src="assets/img/glix-home.png" class="img-fluid animated" style="max-width: 140%;" alt="Using Glix App">
        </div>
      </div>
    </div>

  </section><!-- End Hero -->
</template> 

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {

  }
}
</script>
